import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          pay: "Pay",
          dispute: "Dispute",
          controlFee: "Control fee",
          yourPaymentHasBeenProcessed: "Your payment has been processed",
          youHaveBeenIssuedAControlFee: "You have been issued a control fee",
          yourVehicle: "Your vehicle",
          violationDescription: "A violation as described below has occurred.",
          paymentExpected: "Please pay the violation fee by latest {{date}}",
          typeOfViolation: "Type of violation",
          feeNotPaid: "Fee not paid",
          violationPhotos: "Photos of the violation",
          other: "Other",
          paymentDue: "Amount due",
          choosePaymentType: "Choose payment type",
          paymentCard: "Credit/debit card",
          payWithCard: "Payment",
          processPayment: "Pay",
          loading: 'Loading...',
          genericErrorTitle: 'Error',
          genericErrorDescription: "Something went wrong. Please try again.",
          reportMissing: "Can't find any control fee with the suppled ID.",
          lookupTitle: "Received a control fee?",
          lookupDescription: "Enter your vehicle identification number below to find out if you have received a control fee.",
          paymentCompleted: 'Payment received',
          paymentProcessing: 'Payment is processing',
          paymentUnsuccessful: 'The payment was unsuccessful. Please try again.',
          find: 'Find',
          controlFeePaidTitle: 'Paid',
          controlFeePaidDescription: 'The control fee has been paid.',
          noControlFeeFoundTitle: 'Not found',
          noControlFeeFoundDescription: 'Could not find any control fee for vehicle with license plate number {{licensePlateNumber}}.',
          // Parkering utöver tillåten tid
          ControlFeeReason1: 'Parking for longer than allowed time',
          ControlFeeReason2: 'Parking forbidden',
          // Ej parkerad inom markerad plats
          ControlFeeReason3: 'Not parked within marked area',
          ControlFeeReason4: 'Parkerad på förhyrd/reserverad plats'
        }
      },
      sv: {
        translation: {
          pay: "Betala",
          dispute: "Bestrid",
          controlFee: "Kontrollavgift",
          yourPaymentHasBeenProcessed: "Din betalning är nu genomförd",
          youHaveBeenIssuedAControlFee: "Du har blivit tilldelad en kontrollavgift",
          yourVehicle: "Ditt fordon",
          violationDescription: "Har gjort en överträdelse enligt nedan.",
          paymentExpected: "Vänligen betala in avgiften senast {{date}}",
          typeOfViolation: "Typ av överträdelse",
          feeNotPaid: "Ej erlagd avgift",
          violationPhotos: "Foton på överträdelsen",
          other: "Övrigt",
          paymentDue: "Att betala",
          choosePaymentType: "Välj betalsätt",
          paymentCard: "Betalkort",
          payWithCard: "Betalning",
          processPayment: "Genomför betalning",
          loading: 'Laddar...',
          genericErrorTitle: 'Fel',
          genericErrorDescription: "Något gick fel. Vänligen försök igen.",
          reportMissing: 'Hittar inte någon kontrollavgift med angivet ID.',
          lookupTitle: "Fått en kontrollavgift?",
          lookupDescription: "Ange ditt registreringsnummer nedan för att få svar om du har fått en kontrollavgift.",
          paymentCompleted: 'Betalningen genomförd',
          paymentProcessing: 'Betalningen är under behandling',
          paymentUnsuccessful: 'Betalningen misslyckades. Vänligen försök igen.',
          find: 'Sök',
          controlFeePaidTitle: 'Betald',
          controlFeePaidDescription: 'Kontrollavgiften är betald.',
          noControlFeeFoundTitle: 'Inget resultat',
          noControlFeeFoundDescription: 'Kunde inte hitta någon kontrollavgift för angivet registreringsnummer {{licensePlateNumber}}',
          ControlFeeReason1: 'Parkering utöver tillåten tid',
          ControlFeeReason2: 'Förbud att parkera',
          ControlFeeReason3: 'Ej parkerad inom markerad plats',
          ControlFeeReason4: 'Parkerad på förhyrd/reserverad plats'
        }
      }
    }
  });

  export default i18n;