import React from 'react';
import Lottie from 'lottie-react';
import animSpinnerWhite from 'assets/animations/anim_spinner_white_20x20.json';

const Button = ({ title, onClick, isSecondary, className, isLoading }) => {

    const baseClassName = isSecondary ?
        'font-medium w-full text-black shadow-lg bg-white rounded-[44px] h-11' :
        'font-medium w-full text-white rounded-[44px] shadow-lg h-11';

    if (className) {
        className = `${baseClassName} ${className}`;
    } else {
        className = baseClassName;
    }

    if (!isLoading && !isSecondary) {
        className = `${className} bg-black`;
    } else if (isLoading && !isSecondary) {
        className = `${className} bg-black-opacity-60`;
    }

    return (
        <button onClick={(e) => { onClick(e); }} className={className}>
            {isLoading &&
                <div className="flex w-full items-center justify-center">
                    <Lottie className="h-8 w-8" animationData={animSpinnerWhite} loop={true} />
                </div>
            }
            {!isLoading &&
                title
            }
        </button>
    );
};

export default Button;