import React from 'react';
import { useTranslation } from "react-i18next";
import Lottie from 'lottie-react';
import animSpinnerGreen from 'assets/animations/anim_spinner_green_40x40.json';

const Loading = () => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col w-full h-full items-center justify-center">
            <Lottie className="h-10 w-10" animationData={animSpinnerGreen} loop={true} />
            <span className="font-medium text-2xl mt-2">{t('loading')}</span>
        </div>
        
    );
};

export default Loading;