import { BrowserRouter, Routes, Route } from "react-router-dom";
import DefaultLayout from "layouts/defaultLayout";
import ScrollToTop from 'components/scrollToTop';
import LookupPage from "pages/lookup";
import ReportSummaryPage from "pages/reportSummary";
import StripeCallbackPage from "pages/callback/stripe";
import StripePaymentPage from "pages/payment/stripe";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<LookupPage />} />
            <Route path="/:controlFeeExtId" element={<ReportSummaryPage />} />
            <Route path="/:controlFeeExtId/payment/stripe" element={<StripePaymentPage />} />
            <Route path="/:controlFeeExtId/callback/stripe" element={<StripeCallbackPage />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;