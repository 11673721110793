import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { getReport, setReportAsOpened } from 'api/controlFees';

import { ReactComponent as PinIcon } from 'assets/icons/ico_white_pin_green_p.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/ico_calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/ico_clock.svg';

import Loading from 'components/loading';
import ErrorMessage from 'components/errorMessage';
import Alert from 'components/alert';
import PaymentOptions from 'components/paymentOptions/paymentOptions';
import VehicleIdentifierBox from 'components/vehicleIdentifierBox';
import Button from 'components/button';
import { useMessages } from 'context/messages';

const ReportSummaryPage = () => {
    const [displayPaymentOptions, setDisplayPaymentOptions] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [report, setReport] = useState();
    const { t } = useTranslation();
    const { controlFeeExtId } = useParams();
    const { showToast } = useMessages();

    useEffect(() => {
        const load = async () => {
            try {
                const result = await getReport(controlFeeExtId);
                setReport(result);

                if (result.error) {
                    console.error(result);
                    showToast(t('genericErrorTitle'), t('genericErrorDescription'), 'error');
                }

                if (!result.error) {
                    setReportAsOpened(controlFeeExtId);
                }
            } catch (error) {
                console.error(error);
                showToast(t('genericErrorTitle'), t('genericErrorDescription'), 'error');
            }

            setIsLoading(false);
        }
        setIsLoading(true);
        load();

    }, [controlFeeExtId])

    if (isLoading) {
        return (
            <Loading />
        );
    } else if (report.error && report.error == 'REPORT_NOT_FOUND') {
        return (
            <ErrorMessage title={t('reportMissing')} />
        )
    } else if (report.error) {
        return (
            <ErrorMessage />
        )
    } else {
        return (
            <>
                {report.isPaid &&
                    <div className="flex mt-6">
                        <Alert title={t('controlFeePaidTitle')} description={t('controlFeePaidDescription')} template='success' onClose={() => { }}></Alert>
                    </div>
                }
                <div className="flex w-full justify-center">
                    <h1 className="font-medium md:text-2xl text-base md:mt-16 mt-6">{t('youHaveBeenIssuedAControlFee')}</h1>
                </div>
                <div className="flex flex-col items-center justify-center mt-6">
                    <span className="mb-1">{t('yourVehicle')}:</span>
                    <VehicleIdentifierBox vehicleIdentifier={report.licensePlateNumber} />
                    <div className="flex flex-col justify-center items-center text-center mt-6">
                        <span>{t('violationDescription')}</span>
                        <span>{t('paymentExpected', { date: report.friendlyDueDate })}</span>
                    </div>
                </div>
                <div className="flex mt-6 items-center">
                    <PinIcon className='drop-shadow' /><span className="font-medium ml-2">{report.friendlyLocation}</span>
                </div>
                <div className="flex mt-6">
                    <div className="flex items-center">
                        <CalendarIcon /><span className="font-medium ml-2">{report.createdDateTime.substring(0, 10)}</span>
                    </div>
                    <div className="flex items-center ml-2">
                        <ClockIcon /><span className="ml-2">{report.createdDateTime.substring(11, 16)}</span>
                    </div>
                </div>
                <div className="flex flex-col mt-6">
                    <span className="font-medium">{t('typeOfViolation')}</span>
                    <span>{report.con}</span>
                </div>
                {report.photos &&
                    <div className="flex flex-col mt-6">
                        <span className="font-medium">{t('violationPhotos')}</span>
                        <div className="grid sm:grid-cols-3 gap-2 grid-cols-2">
                            {report.photos.map((photo, i) => {
                                return (
                                    <ImageContainer key={i} imageUrl={photo} />
                                )
                            })}
                        </div>
                    </div>
                }
                {!report.isPaid &&
                    <>
                        <div className="flex justify-between items-center mt-6">
                            <span className="font-medium text-3xl">{t('paymentDue')}</span>
                            <span className="font-medium text-3xl">{report.friendlyAmountDue}</span>
                        </div>
                        <div className="flex gap-y-3 flex-col mt-6">
                            <Button title={t('pay')} onClick={(e) => { e.preventDefault(); setDisplayPaymentOptions(true); }} />
                            <Button title={t('dispute')} isSecondary={true} onClick={(e) => { e.preventDefault(); window.location.assign(`mailto:hello@airpark.app?Subject=Dispute control fee ${controlFeeExtId}`); }} />
                        </div>
                        <PaymentOptions display={displayPaymentOptions} setDisplay={setDisplayPaymentOptions} />
                    </>
                }
            </>
        );
    }
};

const ImageContainer = ({ imageUrl }) => {
    return (
        <a href={imageUrl} target="_blank">
            <img className="w-full mb-3 last:mb-0 rounded-xl" src={imageUrl} />
        </a>
    );
}

export default ReportSummaryPage;