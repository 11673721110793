const BASE_URL = `${process.env.REACT_APP_API_CONTROLFEES_BASE_URL}/ControlFees`

export const getReport = async (controlFeeExtId) => {
  const response = await fetch(BASE_URL + `/${controlFeeExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}

export const setReportAsOpened = async (controlFeeExtId) => {
  const response = await fetch(BASE_URL + `/set-control-fee-as-opened/${controlFeeExtId}`, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json'
    }
  })

  if (response.status === 200) {
    const responseBody = await response.text();

    if (!responseBody) {
      return {};
    } else {
      return JSON.parse(responseBody);
    }
  }

  throw response;
}

export const findReportByLicensePlateNumber = async (licensePlateNumber) => {
  const response = await fetch(BASE_URL + `/find-control-fee-by-license-plate-number/${licensePlateNumber}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}