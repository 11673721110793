import React from "react";
import { Outlet } from "react-router-dom";
import { ReactComponent as AirParkLogo } from 'assets/icons/airpark_logo_plate_black.svg';
import Toast from "components/toast";
import { useMessages } from "context/messages";

export const DefaultLayout = () => {

    const { toast } = useMessages();

    return (
        <>
            <div className="flex justify-center min-h-screen w-full bg-airpark-gray overflow-hidden relative pb-3">
                <a title="AirPark Pay" href="/">
                    <AirParkLogo className="absolute top-6 left-6 z-30 h-12 bg-white rounded-xl" />
                </a>
                <div className="flex fixed bg-screen-width bg-no-repeat z-10 h-[640px] w-full bg-[image:url('./assets/images/background.jpg')]" />
                <div className="flex md:pl-16 md:pr-16 md:pb-16 pl-4 pr-4 pb-4 flex-col max-w-3xl md:mt-64 mt-24 m-3 shadow w-full bg-white rounded-xl z-20">
                    <Outlet />
                </div>
            </div>
            <Toast display={toast.display} title={toast.title} description={toast.description} template={toast.template} />
        </>
    );
};

export default DefaultLayout;