
import React, { useState } from 'react';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { findReportByLicensePlateNumber } from 'api/controlFees';
import { useNavigate } from 'react-router-dom';
import { useMessages } from 'context/messages';

const LookupPage = () => {

    const { t } = useTranslation();
    const [licensePlateNumber, setLicensePlateNumber] = useState();
    const [isLoading, setIsLoading] = useState();
    const navigate = useNavigate();
    const { showToast } = useMessages();

    const find = async () => {

        if (isLoading) {
            return;
        }

        setIsLoading(true);

        try {
            const result = await findReportByLicensePlateNumber(licensePlateNumber);

            if (!result.error) {
                navigate(`/${result.controlFeeExtId}`);
            } else {
                console.error(result);
                showToast(t('noControlFeeFoundTitle'), t('noControlFeeFoundDescription', { licensePlateNumber: licensePlateNumber }), 'info');
            }
        } catch (error) {
            console.error(error);
            showToast(t('genericErrorTitle'), t('genericErrorDescription'), 'error');
        }

        setIsLoading(false);
    }

    return (
        <div className="flex flex-col w-full justify-center items-center">
            <h1 className="font-medium md:text-2xl text-base md:mt-16 mt-6">{t('lookupTitle')}</h1>
            <span className="mt-3">{t('lookupDescription')}</span>
            <input value={licensePlateNumber ?? ''} onKeyDown={(e) => { if (e.key === 'Enter') { find(); } }} onChange={(e) => { setLicensePlateNumber(e.target.value.toUpperCase()); }} autoComplete='off' className="flex max-w-xs text-center pl-14 pr-14 pt-4 pb-4 font-medium text-3xl border-4 border-black rounded-xl focus:outline-none mt-3" placeholder='ABC123'></input>
            <Button title={t('find')} className='mt-6' onClick={(e) => { e.preventDefault(); find(); }} isLoading={isLoading}></Button>
        </div>
    );
};

export default LookupPage;