import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';
import { MessagesProvider } from 'context/messages';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <MessagesProvider>
    <App />
  </MessagesProvider>
  // </React.StrictMode>
);