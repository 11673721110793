import React, { useEffect, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getPaymentIntent } from 'api/payments';
import { useParams } from 'react-router-dom';
import Loading from 'components/loading';
import ErrorMessage from 'components/errorMessage';
import Button from 'components/button';
import { t } from 'i18next';
import { useMessages } from 'context/messages';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);
const appearance = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#33D085',
        colorBackground: '#ffffff',
        colorText: '#000',
        colorDanger: '#df1b41',
        spacingUnit: '0px',
        borderRadius: '12px'
    }
};

const StripePaymentPage = () => {
    const [isLoading, setIsLoading] = useState(true);

    const [options, setOptions] = useState({
        clientSecret: '',
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Rubik' }],
        appearance: appearance
    });

    const { showToast } = useMessages();
    const { controlFeeExtId } = useParams();
    const { t } = useTranslation();

    useEffect(() => {
        setIsLoading(true);

        const load = async () => {
            try {
                const paymentIntent = await getPaymentIntent(controlFeeExtId);
                console.log(paymentIntent);

                if (!paymentIntent.error) {
                    setOptions({ ...options, clientSecret: paymentIntent.clientSecret });
                } else {
                    showToast(t('genericErrorTitle'), t('genericErrorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('genericErrorTitle'), t('genericErrorDescription'), 'error');
            }

            setIsLoading(false);
        }

        load();
    }, []);

    if (!isLoading && options && options.clientSecret) {
        return (
            <>
                <div className="flex flex-col w-full justify-center items-center">
                    <div className="flex bg-white w-full justify-center md:mt-16 mt-6 z-10">
                        <h1 className="font-medium md:text-2xl text-lg pb-6">{t('payWithCard')}</h1>
                    </div>
                </div>
                <Elements stripe={stripePromise} options={options} appearance={appearance}>
                    <CheckoutForm />
                </Elements>
            </>
        );
    }
    else if (isLoading) {
        return (<Loading />);
    } else {
        return (
            <ErrorMessage />
        )
    }
};

const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements();
    const { controlFeeExtId } = useParams();
    const [isLoading, setIsLoading] = useState();
    const { showToast } = useMessages();
    const { t } = useTranslation();

    const handleSubmit = async (event) => {

        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const returnUrl = `${window.location.origin}/${controlFeeExtId}/callback/stripe`;
        console.log(returnUrl);

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            showToast('errorTitle', error.message, 'error');
        } else {
            showToast(t('genericErrorTitle'), t('genericErrorDescription'), 'error');
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement />
            <Button title={t('processPayment')} onClick={(e) => { }} className="mt-6" isLoading={isLoading}></Button>
        </form>
    );
};

export default StripePaymentPage;