const BASE_URL = `${process.env.REACT_APP_API_PAYMENTS_BASE_URL}/Stripe`

export const getPaymentIntent = async (controlFeeExtId) => {
  const response = await fetch(BASE_URL + `/payment-intent-for-control-fee/${controlFeeExtId}`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  })

  if (response.status === 200) {
    return response.json();
  }

  throw response;
}