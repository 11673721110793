import React, { useRef } from "react";

import { ReactComponent as InfoIcon } from 'assets/icons/ico_info_circle_blue.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/ico_thumbs_up_green.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ico_warning_yellow.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico_sad_smiley_red.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_menu_close.svg';


const Alert = ({ title, description, template, onClose }) => {
    const alertRef = useRef();
    const alertBaseStyle = 'flex z-0 min-h-24 relative items-center p-6 w-full rounded-[30px] shadow-lg border-[1px] border-solid';
    let alertStyle = '';

    switch (template) {
        case 'info':
            alertStyle = `${alertBaseStyle} bg-airpark-blue-background border-airpark-blue`;
            break;
        case 'success':
            alertStyle = `${alertBaseStyle} bg-airpark-green-background border-airpark-green`;
            break;
        case 'warning':
            alertStyle = `${alertBaseStyle} bg-airpark-yellow-background border-airpark-yellow`;
            break;
        case 'error':
            alertStyle = `${alertBaseStyle} bg-airpark-red-background border-airpark-red`;
            break;
        default:
            alertStyle = `${alertBaseStyle} bg-airpark-blue-background border-airpark-blue`;
            break;
    }

    return (
        <div ref={alertRef} onClick={() => { onClose(); }} className={alertStyle}>
            {template == 'info' &&
                <InfoIcon className="md:flex w-8 hidden mr-8" />
            }
            {template == 'success' &&
                <SuccessIcon className="md:flex w-8 hidden mr-8" />
            }
            {template == 'warning' &&
                <WarningIcon className="md:flex w-8 hidden mr-6" />
            }
            {template == 'error' &&
                <ErrorIcon className="md:flex w-8 hidden mr-8" />
            }
            <div className="flex flex-col w-full">
                <span className="font-medium text-lg pb-1">{title}</span>
                <span className="font-normal text-base">{description}</span>
            </div>
            {onClose &&
                <button className="flex absolute right-9 top-6" onClick={(e) => { e.preventDefault(); onClose(); }}><CloseIcon /></button>
            }
        </div>
    );
}

export default Alert;