import React from 'react';
import { useTranslation } from "react-i18next";
import { ReactComponent as ErrorIcon } from 'assets/icons/ico_sad_smiley_red.svg';

const ErrorMessage = ({title}) => {

    const {t} = useTranslation();
    
    if (!title) {
        title = t('error');
    }

    return (
        <div className="flex w-full h-full items-center justify-center">
            <div className="flex flex-col max-w-xs w-full justify-center items-center mt-16">
            <ErrorIcon className="w-16 h-16" />
            <span className="mt-6">{title}</span>
            </div>
        </div>
    );
};

export default ErrorMessage;