import React from 'react';

const VehicleIdentifierBox = ({ vehicleIdentifier }) => {
    return (
        <div className="w-fit inline-block border-4 border-black rounded-xl">
            <span className="flex pl-14 pr-14 pt-4 pb-4 font-medium text-3xl">{vehicleIdentifier}</span>
        </div>
    );
};

export default VehicleIdentifierBox;