import React from 'react';
import { ReactComponent as SwishIcon } from 'assets/icons/ico_swish.svg';
import { ReactComponent as CreditCardIcon } from 'assets/icons/ico_credit-card.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/ico_close.svg';
import { useNavigate } from 'react-router-dom';


const PaymentOptions = ({ display, setDisplay }) => {

    const navigate = useNavigate();

    return (
        <>
            {display &&
                <div onClick={() => { setDisplay(false) }} className="absolute right-0 top-0 bg-black opacity-60 z-10 h-full w-full">

                </div>
            }
            <div className={`flex flex-col max-w-full w-full absolute right-0 transition-all duration-[650ms] ease-in-out z-20 bg-white shadow-payment-options-box rounded-t-xl pt-6 pb-14 ${display ? 'bottom-[0px]' : 'bottom-[-100%]'}`}>
                <div className="flex relative w-full justify-center">
                    <h2 className="font-medium text-xl">Välj betalsätt</h2>
                    <button onClick={(e) => { e.preventDefault(); setDisplay(false); }} className="flex absolute right-6 bg-white rounded-full h-8 w-8 items-center justify-center shadow-md">
                        <CloseIcon />
                    </button>
                </div>
                <div className="flex flex-col w-full justify-center items-center mt-6">
                    <PaymentOptionButton onClick={(e) => { e.preventDefault(); navigate('payment/stripe'); }} title='Betalkort' icon={<CreditCardIcon />} />
                    {/* <PaymentOptionButton title='Swish' icon={<SwishIcon className="h-6 w-16" />} /> */}
                </div>
            </div>
        </>

    );
};

const PaymentOptionButton = ({ title, icon, onClick }) => {
    return (
        <button onClick={(e) => { onClick(e); }} className="flex max-w-xs w-full h-11 rounded-xl shadow-md items-center mb-2 last:mb-0">
            <div className="flex w-full justify-between pl-6 pr-6">
                <span>{title}</span>
                {icon}
            </div>
        </button>
    )
}

export default PaymentOptions;