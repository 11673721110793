import React, { useState, useEffect } from 'react';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { getReport } from 'api/controlFees';
import { loadStripe } from '@stripe/stripe-js';

import { useMessages } from 'context/messages';

import { ReactComponent as SuccessIcon } from 'assets/icons/ico_success.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/ico_warning_yellow.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/ico_sad_smiley_red.svg';

import VehicleIdentifierBox from 'components/vehicleIdentifierBox';
import Loading from 'components/loading';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY);

const StripeCallbackPage = () => {


    const [isLoading, setIsLoading] = useState();
    const { controlFeeExtId } = useParams();
    const { showToast } = useMessages();
    const [licensePlateNumber, setLicensePlateNumber] = useState();
    const [friendlyAmountDue, setFriendlyAmountDue] = useState();

    useEffect(() => {
        const load = async () => {
            const result = await getReport(controlFeeExtId);

            if (!result.error) {
                setLicensePlateNumber(result.licensePlateNumber);
                setFriendlyAmountDue(result.friendlyAmountDue);
            } else {
                showToast(t('genericErrorTitle'), t('genericErrorDescription'), 'error');
            }

            setIsLoading(false);
        }

        setIsLoading(true);
        load();

    }, [controlFeeExtId]);

    if (isLoading) {
        return (
            <Loading />
        )
    }

    return (
        <Elements stripe={stripePromise}>
            <div className="flex flex-col w-full justify-center items-center">
                <StripeMessage licensePlateNumber={licensePlateNumber} friendlyAmountDue={friendlyAmountDue} />
            </div>
        </Elements>
    );
};

const StripeMessage = ({ licensePlateNumber, friendlyAmountDue }) => {

    const stripe = useStripe();
    const [message, setMessage] = useState({ title: '', status: '', amount: '' });

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage({ title: t('paymentCompleted'), status: 'success' });
                    break;
                case "processing":
                    setMessage({ title: t('paymentProcessing'), status: 'processing' });
                    break;
                case "requires_payment_method":
                    setMessage({ title: t('paymentUnsuccessful'), status: 'error' });
                    break;
                default:
                    setMessage({ title: t('paymentUnsuccessful'), status: 'error' });
                    break;
            }
        });
    }, [stripe]);

    return (
        <>
            <div className="flex bg-white w-full justify-center md:mt-16 mt-6 z-10">
                <h1 className="font-medium md:text-2xl text-lg pb-6">{message.title}</h1>
            </div>
            <div className="flex flex-col StyledReceipt max-w-sm w-full pb-12 justify-center items-center">
                {message.status === 'success' &&
                    <>
                        <SuccessIcon className="mt-11" />
                        <span className="font-medium mt-6 mb-6">{t('controlFee')}</span>
                        <VehicleIdentifierBox vehicleIdentifier={licensePlateNumber} />
                        <span className="font-medium text-2xl mt-6 mb-11">{friendlyAmountDue}</span>
                    </>
                }
                {message.status === 'processing' &&
                    <WarningIcon />
                }
                {message.status === 'error' &&
                    <ErrorIcon />
                }
            </div>
        </>

    )
}

export default StripeCallbackPage;